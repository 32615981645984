<template>
  <div v-if="isError" id="unauthorized" class="page">
    <CircleMessage>
      <b>{{ translate('OOPS') }}...</b><br>
      <span>{{ errorMessage }}</span>
    </CircleMessage>
  </div>
  <div v-else id="upload" class="page">
    <template v-if="show === 'UPLOAD'">
      <Breadcrumbs :current="documentTypeIndex" :documentTypes="documentTypes"/>
      <Uploader v-on:uploads="uploads" :document-type="documentType" :uploading="loading"
                :max-upload-size="maxFilesToUpload"/>
      <div class="button-container">
        <button
            id="previous-button"
            class="shadowed"
            :disabled="loading || documentTypeIndex === 0"
            v-on:click="() => documentTypeIndex -= 1">
          {{ translate('PREVIOUS') }}
        </button>
        <button v-if="documentTypeIndex < documentTypes.length - 1"
                id="next-button"
                class="shadowed"
                :disabled="loading || (nextAfterOCR && !this.currentDocumentTypeOCRStatuses.includes('SUCCESS'))"
                v-on:click="() => documentTypeIndex+=1">
          {{ translate('NEXT') }}
        </button>
        <button v-else
                id="end-button"
                class="shadowed primary"
                :disabled="loading || !allDocumentTypesAreFilled"
                :title="!allDocumentTypesAreFilled ? translate('UPLOAD.MISSING_DOCUMENTS') : ''"
                v-on:click="doOcr">
          {{ translate('END') }}
        </button>
      </div>

      <DocumentCounter :value="uploadCount"/>
      <p class="max-upload-size" v-if="multipartProperties">
        {{ translate('UPLOAD.MAX_UPLOAD_SIZE') }} {{ multipartProperties.pretty }}</p>
      <p class="max-files-length" v-if="maxRequestSize">
        {{ translate('UPLOAD.MAX_FILES_LENGTH') }} {{ maxFilesToUpload }}</p>
    </template>
    <SuccessCircleMessage v-else-if="show === 'SUCCESS'" v-on:back="()=>{
      ocrDone = false;
      documentTypeIndex = 0;
    }" :back-button="!blocking"/>
    <ErrorCircleMessage v-else-if="show === 'ERROR' && !isError  && task !== null"/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import JWTDecoder, {JWTTokenInfo} from '@/jwt/JWTDecoder';
import ProofPrinter from '@/components/ProofPrinter.vue';
import Uploader from '@/components/Uploader.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DocumentCounter from '@/components/DocumentCounter.vue';
import {getMaxFileSize, getMaxRequestSize, getTask, ocr, uploadMultipleDocuments} from '@/api';
import CircleMessage from '@/components/circleMessage/CircleMessage.vue';
import {DocumentDto, Task2, UploadTaskCauses, UploadTaskResult} from '@/types/Task';
import {translate} from '@/i18n';
import type {MultipartProperties} from "@/types/MultipartProperties";
import {TYPE, useToast} from 'vue-toastification';
import SuccessCircleMessage from '@/components/circleMessage/SuccessCircleMessage.vue';
import ErrorCircleMessage from '@/components/circleMessage/ErrorCircleMessage.vue';
import {checkIfInIframe} from "@/utils/frameUtils";

export default defineComponent({
  name: 'Upload',
  components: {
    ErrorCircleMessage,
    SuccessCircleMessage,
    CircleMessage, DocumentCounter, Breadcrumbs, ProofPrinter, Uploader
  },
  data: () => ({
    tokenInfo: null as JWTTokenInfo | null,
    task: null as Task2<UploadTaskCauses, UploadTaskResult> | null,
    documentTypeIndex: 0 as number,
    loading: false as boolean,
    multipartProperties: undefined as MultipartProperties | undefined,
    maxRequestSize: undefined as MultipartProperties | undefined,
    ocrDone: false as boolean,
    isError: false as boolean,
    code: 0 as number
  }),
  computed: {
    nextAfterOCR(): boolean {
      const doc = this.task?.causes?.documents
          .filter(({documentType}) => documentType == this.documentType)[0];
      if (!doc) return false;
      return doc?.nextAfterOCR || false;
    },
    currentDocumentTypeOCRStatuses(): string[] {
      return this.task?.result?.ocrs[this.documentType]
          ?.map(({task}) => task)
          ?.map(({status}) => status) || []
    },
    maxFilesToUpload(): number {
      if (this.documentType === "ID_CHECK") {
        return 2;
      }
      if (!this.multipartProperties || !this.maxRequestSize) return 0;
      const res = this.maxRequestSize.bytes / (this.multipartProperties.bytes);
      return Math.floor(res);
    },
    errorMessage() {
      switch (this.code) {
        case 401:
          return this.translate('ERROR.UNAUTHORIZED');
        case 502:
          return this.translate('ERROR.BAD_GATEWAY');
        default:
          return this.translate('ERROR.UNKNOWN');
      }
    },
    blocking(): boolean {
      return this.task?.causes?.blocking || true;
    },
    status(): string {
      if (!this.task) return 'ERROR';
      return this.task.status;
    },
    show(): 'UPLOAD' | 'SUCCESS' | 'ERROR' {
      if (!this.task) return 'ERROR';
      if (this.blocking) {
        if (this.status === 'SUCCESS') {
          return 'SUCCESS';
        }
        if (this.status === 'FAILED') {
          return 'ERROR';
        }
      }
      if (this.ocrDone) {
        return 'SUCCESS';
      }
      if (this.status === 'FAILED') return 'ERROR';
      return 'UPLOAD';
    },
    accessToken(): string {
      const {accessToken} = this.$route.query;
      if (!accessToken || typeof accessToken !== 'string') {
        return '';
      }
      return accessToken;
    },
    documentTypes(): string[] {
      return this.task?.causes?.documentTypes || [];
    },
    documentType(): string {
      return this.documentTypes[this.documentTypeIndex];
    },

    uploadCount(): number {
      const uploads = this.task?.result?.uploads;
      if (!uploads || !uploads[this.documentType]) return 0;

      return uploads[this.documentType].length;
    },
    allDocumentTypesAreFilled(): boolean {
      if (!this.task || !this.task.result) return false;
      const {uploads, ocrs} = this.task.result;

      if (!uploads) {
        return false;
      }

      if (this.blocking)
        return this.documentTypes.map(documentType => uploads[documentType])
            .filter(documentIds => !!documentIds || documentIds)
            .filter(({length}) => length > 0)
            .length === this.documentTypes.length;

      for (const documentType of this.documentTypes) {
        const uploadedDocuments: DocumentDto[] = uploads[documentType] || [];
        const ocrDocumentIds = ocrs[documentType].flatMap(({documents}) => documents)
            .map(({documentId}) => documentId) || [];

        if (uploadedDocuments
            .filter(({documentId}) => !ocrDocumentIds.includes(documentId))
            .length > 0) return true;
      }
      return false;
    }

  },
  methods: {
    translate,
    async refreshTask() {
      let res: Response;
      try {
        res = await getTask(this.accessToken);
      } catch (e) {
        this.goToErrorView(502);
        return;
      }
      const {status} = res;
      if (status != 200) {
        this.goToErrorView(status);
        return;
      }
      this.task = await res.json();
    },
    goToErrorView(status: number) {
      this.code = status;
      this.isError = true;
    },
    async uploads(files: File[]) {
      let res: Response;
      this.loading = true;
      if (this.multipartProperties === undefined) {
        this.toast(`${translate('TOAST.CONFIGURATION_FAILED')}`, {
          type: TYPE.ERROR,
          timeout: false
        });
        this.loading = false;
        return;
      }

      if (files.length > this.maxFilesToUpload) {
        if (this.documentType === "ID_CHECK") {
          this.toast(`${translate('TOAST.TOO_MANY_FILES_ID_CHECK')}`, {
            type: TYPE.ERROR,
            timeout: false
          });
        } else {
          this.toast(`${translate('TOAST.TOO_MANY_FILES').replace("__max__", `${this.maxFilesToUpload}`)}`, {
            type: TYPE.ERROR,
            timeout: false
          });
        }
        this.loading = false;
        return;
      }

      let error = false;
      for (const file of files) {
        if (file.size <= this.multipartProperties.bytes) {
          continue;
        }
        error = true;
        this.toast(`${translate('TOAST.TOO_LARGE_FILE')}: ${file.name}`, {
          type: TYPE.ERROR,
          timeout: false
        });
        this.loading = false;
      }
      if (error) {
        return;
      }

      const toastId = this.toast(`${translate('TOAST.UPLOADING')} `, {
        type: TYPE.DEFAULT,
        timeout: false
      });

      try {
        res = await uploadMultipleDocuments(this.accessToken, this.documentType, files);
      } catch (e) {
        this.toast.dismiss(toastId);
        this.loading = false;
        this.goToErrorView(502);
        return;
      }
      const {status} = res;
      if (status != 200) {
        this.toast.error(`${translate('TOAST.ERROR')}:  (code: ${status})`, {
          id: toastId,
          timeout: false
        });
        this.loading = false;
        return;
      }

      await this.refreshTask();
      this.toast.success(`${translate('TOAST.UPLOADED')} `, {
        id: toastId
      });
      if (this.nextAfterOCR) {
        const ocrToastId = this.toast(`${translate('TOAST.ANALYZING')} `, {
          type: TYPE.DEFAULT,
          timeout: false
        })

        const i = setInterval(async () => {
          await this.refreshTask();
          const stillInProgress = this.currentDocumentTypeOCRStatuses?.includes('IN_PROGRESS');
          const atLeastOneSuccess = this.currentDocumentTypeOCRStatuses?.includes('SUCCESS');
          if (stillInProgress) {
            return;
          }
          clearInterval(i)
          this.loading = false;
          if (atLeastOneSuccess) {
            this.toast.success(`${translate('TOAST.ANALYZED')} `, {
              id: ocrToastId
            });
          } else {
            this.toast.error(`${translate('TOAST.ANALYZE_ERROR')} `, {
              id: ocrToastId,
              timeout: false
            });
          }
        }, 2000)
      } else {
        this.loading = false;
      }
    },
    async doOcr() {
      let res: Response;
      this.loading = true;
      const toastId = this.toast(`${translate('TOAST.SEND_OCR')}...`, {
        type: TYPE.DEFAULT,
        timeout: false
      });
      try {
        res = await ocr(this.accessToken);
      } catch (e) {
        this.goToErrorView(502);
        this.toast.dismiss(toastId);
        this.loading = false;
        return;
      }
      this.loading = false;
      const {status} = res;
      if (status !== 200) {
        console.error('Unable to do OCR');
        this.toast.error(`${translate('TOAST.OCR_ERROR')} (code: ${status})`, {
          id: toastId,
          timeout: false
        });
        return;
      }
      this.toast.success(translate('TOAST.OCR_SUCCESS'), {id: toastId});
      this.task = await res.json();
      // send to parent when we are in IFRAME
      window.parent.postMessage('OCR_DONE', '*');
      this.ocrDone = true;
    }
  },
  async created() {
    if (!this.accessToken) {
      console.error('No access token');
      this.goToErrorView(401);
      return;
    }
    try {
      this.tokenInfo = JWTDecoder.decode(this.accessToken);
    } catch (e) {
      console.error('Unable to decode jwt', e);
      this.goToErrorView(401);
      return;
    }

    await this.refreshTask();
    await getMaxFileSize(this.accessToken).then(multipartProperties => this.multipartProperties = multipartProperties);
    await getMaxRequestSize(this.accessToken).then(maxUploadSize => this.maxRequestSize = maxUploadSize);
  },
  setup() {
    const toast = useToast();
    return {toast};
  },
  mounted() {
    checkIfInIframe();
  }
});
</script>

<style scoped lang="less">

body.in-iframe #upload {
  .max-upload-size {
    font-size: .9rem;
  }
}

#upload {
  justify-content: center;
  align-items: center;

  button {
    align-self: center;
  }

  .all-sent {
    flex-grow: 1;
  }

  .no-files {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .button-container button {
    margin: 2%;
  }

  .max-upload-size {
    color: red;
    margin-bottom: 0;
  }

  .max-files-length {
    font-size: 14.4px;
    margin-top: .25em;
    color: red;
  }
}

.proof-printer {
  margin-top: 5%;
  padding: 0 15%;
}

body.in-frame unauthorized {
  height: 0;
}

#unauthorized {
  height: 100%;
}

</style>
